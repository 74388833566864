type TLink = {
  uid: string;
  type: string;
};

export const linkResolver = (link: TLink) => {
  if(link) {
    if (link.type === 'company_page') {
      return '/company';
    }

    if (link.type === 'driven_by_tech') {
      return '/driven-by-tech';
    }

    if (link.type === 'our_brands_page') {
      return '/our-brands';
    }

    if (link.type === 'our_depots_page') {
      return '/our-depots';
    }

    if (link.type === 'expert_knowledge_page') {
      return '/expert-knowledge';
    }

    if (link.type === 'careers_page') {
      return '/jobs';
    }

    if (link.type === 'vacancy') {
      return `/`;
    }

    if (link.type === 'textual_page') {
      return `/${link.uid}`;
    }

    if (link.type === 'news_article') {
      return `/news/${link.uid}`;
    }
  }
  // Backup for all other types
  return '/';
};
